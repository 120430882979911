import { Button, Flex, Text } from '@chakra-ui/react'
import { CalculateInvestmentPayload } from 'generated/graphql'
import React from 'react'
import { formatDate, formatPrice } from 'utils'

type CalculationResultsProps = {
  results: CalculateInvestmentPayload
  onPurchase: () => void
}

type CalculationResultItemProps = {
  value: string | number
  title: string
}

const CalculationResultItem: React.FC<CalculationResultItemProps> = ({ title, value }) => {
  return (
    <Flex mb={2}>
      <Text fontSize="md" fontWeight="600" mr={2}>
        {title}:
      </Text>
      <Text fontSize="md">{value}</Text>
    </Flex>
  )
}

const CalculationResults: React.FC<CalculationResultsProps> = ({ results, onPurchase }) => {
  const { interestRate, investmentDate, investmentTerm, investorTotalReturn, purchasePrice } =
    results

  return (
    <Flex flexDirection="column">
      <Text fontSize={{ base: 'md', md: 'lg' }} color="text" fontWeight="600" mb={5}>
        Calculation Results
      </Text>
      <Flex bg="gray.100" p={4} borderRadius="2xl" flexDir={{ base: 'column', md: 'row' }}>
        <Flex flex={0.5} flexDirection="column">
          <CalculationResultItem title="Interest Rate" value={`${interestRate}%`} />
          <CalculationResultItem
            title="Investment term"
            value={`${investmentTerm} ${investmentTerm && investmentTerm > 1 ? 'months' : 'month'}`}
          />
          <CalculationResultItem title="Investment Date" value={formatDate(investmentDate)} />
        </Flex>
        <Flex flex={0.5} flexDirection="column">
          <CalculationResultItem
            title="Investor total return"
            value={formatPrice(investorTotalReturn || 0)}
          />
          <CalculationResultItem
            title="Investment amount"
            value={formatPrice(purchasePrice || 0)}
          />
        </Flex>
      </Flex>

      <Button
        onClick={onPurchase}
        bg="primary"
        width={{ base: '100%', md: 'auto' }}
        color="white"
        borderRadius="full"
        my={5}
        alignSelf="flex-end"
      >
        Purchase
      </Button>
    </Flex>
  )
}

export default CalculationResults
