import { lazy } from 'react'
import { RouteProps } from 'react-router'
export interface PrivateRouteObject extends RouteProps {
  exact: boolean
  path: string
  breadcrumb?: string
  component: any
  title: string
  hideSideBar?: boolean
}

const Profile = lazy(() => import('../containers/Profile'))
const Authentication = lazy(() => import('../containers/Authentication'))
const ConfirmEmail = lazy(() => import('../containers/ConfirmEmail'))
const ForgotPassword = lazy(() => import('../containers/ForgotPassword'))
const ResetPassword = lazy(() => import('../containers/ResetPassword'))
const SplashScreen = lazy(() => import('../containers/SplashScreen'))
const RoleSelect = lazy(() => import('../containers/RoleSelect'))
const ConfirmedScreen = lazy(() => import('../containers/ConfirmedScreen'))
const CorporateOnboarding = lazy(() => import('../containers/CorporateOnboarding'))
const Notes = lazy(() => import('../containers/Notes'))
const NotesCreate = lazy(() => import('../containers/NoteCreate'))
const InvestorOnboarding = lazy(() => import('../containers/InvestorOnboarding'))
const NoteDetailView = lazy(() => import('../containers/NoteDetailView'))
const Dashboard = lazy(() => import('../containers/Dashboard'))
const Notifications = lazy(() => import('../containers/RegulatoryBody/Notifications'))
const HelpAndSupport = lazy(() => import('containers/HelpAndSupport'))

const SHARED_ROUTES: PrivateRouteObject[] = [
  {
    exact: true,
    title: 'Help & Support',
    path: '/auth/help-and-support',
    component: HelpAndSupport
  }
]

const PRIVATE_ROUTES: PrivateRouteObject[] = [
  {
    exact: false,
    path: '/auth/my-dashboard',
    breadcrumb: 'My Dashboard',
    component: Dashboard,
    title: 'My Dashboard'
  },
  {
    exact: false,
    path: '/auth/profile',
    breadcrumb: 'My Profile',
    component: Profile,
    title: 'My Profile'
  },
  {
    exact: true,
    title: 'Main Onboarding',
    path: '/auth/main-onboarding',
    component: RoleSelect,
    hideSideBar: true
  },
  {
    exact: true,
    title: 'Corporate Onboarding',
    path: '/auth/corporate-onboarding',
    component: CorporateOnboarding
  },
  {
    exact: true,
    title: 'My Notes',
    path: '/auth/notes',
    component: Notes
  },
  {
    exact: false,
    title: 'Create note',
    path: '/auth/note/create/:step/:id?',
    breadcrumb: 'Create note',
    component: NotesCreate,
    hideSideBar: true
  },
  {
    exact: true,
    title: 'Notifications',
    path: '/auth/notifications',
    component: Notifications
  },
  {
    exact: true,
    title: 'Investor Onboarding',
    path: '/auth/investor-onboarding',
    component: InvestorOnboarding
  },
  {
    exact: true,
    title: 'My note details',
    path: '/auth/notes/:view/:id',
    component: NoteDetailView
  },
  ...SHARED_ROUTES
]

const PUBLIC_ROUTES = [
  {
    exact: true,
    title: 'Login',
    path: '/login',
    component: Authentication
  },
  {
    exact: true,
    title: 'Register',
    path: '/register',
    component: Authentication
  },
  {
    exact: true,
    title: 'Confirm Email',
    path: '/confirm-email',
    component: ConfirmEmail
  },
  {
    exact: true,
    title: 'Forgot Password',
    path: '/forgot-password',
    component: ForgotPassword
  },
  {
    exact: true,
    title: 'Reset Password',
    path: '/reset-password',
    component: ResetPassword
  },
  {
    exact: true,
    title: 'Create Password',
    path: '/create-password',
    component: ResetPassword
  },
  {
    exact: true,
    title: 'Splash Screen',
    path: '/',
    component: SplashScreen
  },
  {
    exact: true,
    title: 'Account Confirmed',
    path: '/confirmed',
    component: ConfirmedScreen
  }
]

const AUTH_ROUTES: PrivateRouteObject[] = [
  {
    exact: true,
    title: 'Main Onboarding',
    path: '/auth/main-onboarding',
    component: RoleSelect,
    hideSideBar: true
  }
]

export { PUBLIC_ROUTES, PRIVATE_ROUTES, AUTH_ROUTES, SHARED_ROUTES }
