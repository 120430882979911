import { isEqual } from 'lodash'
import * as React from 'react'
import { STRAPI_USER_STORAGE_KEY } from '../../constants'
import {
  AppUse,
  useChangeAppUseMutation,
  useChangeMyPasswordMutation,
  UsersPermissionsUser
} from '../../generated/graphql'
import { useBrowserStorage } from '../../hooks'
import strapiHelpers, { RegisterInput, StrapiLoginPayload } from '../../utils/strapiHelpers'

type AuthProviderProps = {
  logout: () => void
  isAuthenticating: boolean
  isAuthenticated: boolean
  user: UsersPermissionsUser
  register: (input: RegisterInput) => Promise<void>
  login: (email: string, password: string, rememberMe: boolean) => Promise<void>
  setUser: React.Dispatch<React.SetStateAction<UsersPermissionsUser | undefined>>
  changeAppUse: (useType: AppUse, callback: (roleType: string) => void) => Promise<void>
  resetPassword: (code: string, password: string, email: string) => Promise<void>
  hasAccessTo: (roleType: RoleType) => boolean | undefined
  userRole: RoleType
  changeMyPassword: (password: string) => Promise<void>
}

export type RoleType = 'regulatory' | 'investor' | 'corporate' | 'authenticated' | 'manager'

const AuthContext = React.createContext<Partial<AuthProviderProps>>({})

export const useAuthContext = (): Partial<AuthProviderProps> => React.useContext(AuthContext)

type UserStorage = StrapiLoginPayload | null

const AuthProvider: React.FC = ({ children }) => {
  const [localUser, setLocalUser, removeLocalUser] = useBrowserStorage<UserStorage>(
    STRAPI_USER_STORAGE_KEY,
    'local'
  )

  const [sessionUser, setSessionUser, removeSessionUser] = useBrowserStorage<UserStorage>(
    STRAPI_USER_STORAGE_KEY,
    'session'
  )

  const [isAuthenticated, setIsAuthenticated] = React.useState(false)
  const [isAuthenticating, setIsAuthenticating] = React.useState(true)
  const [userRole, setUserRole] = React.useState<RoleType | undefined>()

  const [user, setUser] = React.useState(sessionUser?.user || localUser?.user)

  const [changeAppUseMutation] = useChangeAppUseMutation()
  const [changeMyPasswordMutation] = useChangeMyPasswordMutation()

  const persistUser = (data: StrapiLoginPayload, rememberMe?: boolean) => {
    rememberMe ? setLocalUser(data) : setSessionUser(data)
    setUser(data?.user)
  }

  const logout = () => {
    removeLocalUser()
    removeSessionUser()
    setIsAuthenticated(false)
    setUser(undefined)
  }

  React.useEffect(() => {
    if (user?.confirmed) {
      setUserRole(user?.role?.type as RoleType)
      setIsAuthenticated(true)
      if (localUser?.user && !isEqual(localUser?.user, user)) {
        setLocalUser({ jwt: localUser.jwt, user })
      }
      if (sessionUser?.user && !isEqual(sessionUser?.user, user)) {
        setSessionUser({ jwt: sessionUser.jwt, user })
      }
    } else {
      setIsAuthenticated(false)
    }
    setIsAuthenticating(false)
    // eslint-disable-next-line
  }, [user])

  const login = async (email: string, password: string, rememberMe: boolean) => {
    try {
      setIsAuthenticating(true)
      const { data } = await strapiHelpers.login(email, password)
      persistUser(data, rememberMe)
      setIsAuthenticating(false)
    } catch (error) {
      setIsAuthenticating(false)
      throw error
    }
  }

  const register = async ({
    username,
    firstName,
    citizenship,
    dateOfBirth,
    lastName,
    phoneNumber,
    email,
    password
  }: RegisterInput) => {
    try {
      setIsAuthenticating(true)
      const { data } = await strapiHelpers.register({
        username,
        firstName,
        citizenship,
        dateOfBirth,
        lastName,
        phoneNumber,
        email,
        password
      })
      persistUser(data)
      setIsAuthenticating(false)
    } catch (error) {
      setIsAuthenticating(false)
      throw error
    }
  }

  const changeAppUse = async (useType: AppUse, callback: (roleType: string) => void) => {
    try {
      const { data } = await changeAppUseMutation({ variables: { useType } })
      setUser(data?.changeAppUse as UsersPermissionsUser)
      callback(data?.changeAppUse?.role?.type || 'corporate')
    } catch (error) {
      throw error
    }
  }

  const resetPassword = async (code: string, password: string, email: string) => {
    try {
      const { data } = await strapiHelpers.resetPassword(code, password, email)
      persistUser(data)
    } catch (error) {
      throw error
    }
  }

  const hasAccessTo = (roleType: RoleType) => {
    if (user) {
      return user?.role?.type === roleType
    }
  }

  const changeMyPassword = async (password: string) => {
    try {
      await changeMyPasswordMutation({ variables: { password } })
    } catch (error) {
      throw error
    }
  }

  return (
    <AuthContext.Provider
      value={{
        login,
        register,
        logout,
        isAuthenticated,
        isAuthenticating,
        user,
        setUser,
        changeAppUse,
        hasAccessTo,
        userRole,
        resetPassword,
        changeMyPassword
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export default AuthProvider
