import { Box, Button, Flex, FlexProps, Icon, Image, Text, useMediaQuery } from '@chakra-ui/react'
import { Breadcrumbs, NotifierBell } from 'components'
import { useAppContext } from 'context/AppProvider'
import { useAuthContext } from 'context/AuthProvider/index'
import { useTransactionsContext } from 'context/TransactionsProvider'
import dayjs from 'dayjs'
import {
  Enum_Userspermissionsuser_Onboardingstatus,
  useMyWalletBalanceLazyQuery
} from 'generated/graphql'
import useWindowMaxSize from 'hooks/useWindowMaxSize'
import { get } from 'lodash'
import { NavItem } from 'navigation'
import { PrivateRouteObject } from 'navigation/routes'
import * as React from 'react'
import { AiTwotoneLock } from 'react-icons/ai'
import { IoMdClose } from 'react-icons/io'
import { useHistory, useLocation } from 'react-router-dom'
import { images } from 'theme'
import { formatPrice } from 'utils'
import { useNotificationsContext } from '../../context/NotificationsProvider'

type SideBarProps = FlexProps & {
  accentColor: string
  borderColor?: string
  closeOnNavigate?: boolean
  color: string
  hoverColor: string
  navItems: NavItem[]
  tooltipBg?: string
  tooltipColor?: string
  routes: PrivateRouteObject[]
}

const SideBar: React.FC<SideBarProps> = ({ children, navItems }) => {
  const { user, userRole, logout } = useAuthContext()
  const { notifications } = useNotificationsContext()
  const { walletBalance, setBalance } = useTransactionsContext()
  const { maxWidth, maxHeight } = useWindowMaxSize()
  const { pathname } = useLocation()
  const { push } = useHistory()
  const [isMobileSize] = useMediaQuery('(max-width: 768px)')
  const { drawerOpen, toggleDrawer, closeDrawer } = useAppContext()

  const [myWalletBalance] = useMyWalletBalanceLazyQuery({
    onCompleted: (res) => {
      setBalance?.(res?.myWalletBalance as number)
    },
    fetchPolicy: 'no-cache'
  })
  React.useEffect(() => {
    myWalletBalance()
  }, [])

  const history = useHistory()
  const sidebarRef = React.useRef<HTMLDivElement>(null)

  React.useEffect(() => {
    const unlisten = history.listen(() => {
      closeDrawer()
    })
    const handleClickOutside = (event: MouseEvent) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target as Node)) {
        closeDrawer()
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      unlisten()
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [history, closeDrawer])

  React.useEffect(() => {
    closeDrawer()
  }, [maxWidth])

  if (
    user?.role?.type === 'corporate' &&
    user?.onboardingStatus !== Enum_Userspermissionsuser_Onboardingstatus.Completed
  ) {
    return (
      <Box width="100vw" height={maxHeight}>
        {children}
      </Box>
    )
  }

  const companyName = get(user, 'company.name')
  const activeSince = get(user, 'company.activeSince')

  const fullName = `${get(user, 'firstName', '')} ${get(user, 'lastName', '')}`

  return (
    <Flex height={maxHeight} bg="background" width="100%" flexDirection="row" position="relative">
      <Flex position="relative" width={{ md: '20%' }} zIndex={1000} ref={sidebarRef}>
        <Flex position="fixed" left={0} top={0} bottom={0} height="100%" width={{ md: '20%' }}>
          <Flex
            paddingTop={5}
            display={!drawerOpen && isMobileSize ? 'none' : 'flex'}
            borderRadius={{ md: 30 }}
            height={{ base: maxHeight, md: '96%' }}
            maxWidth="350px"
            minWidth="257px"
            boxShadow="5px 7px 42px 3px rgba(0,0,0,0.2)"
            borderColor="text"
            bg="white"
            m={{ md: 'auto 15px' }}
            alignItems="center"
            flexDirection="column"
            overflow="hidden auto"
          >
            <Icon
              mr={2}
              display={{ md: 'none' }}
              onClick={() => {
                toggleDrawer()
              }}
              position="absolute"
              height="25px"
              w="25px"
              alignSelf="flex-end"
              as={IoMdClose}
            ></Icon>

            <Image src={images.logo} width="6rem" objectFit="contain" />
            <Flex flexDirection="row" alignItems="center" mt={5}>
              <Image
                borderRadius="full"
                src={
                  userRole === 'corporate' ? user?.company?.logo?.url : user?.profilePicture?.url
                }
                fallbackSrc={images.logo}
                width="3rem"
                height="3rem"
                shadow="lg"
                objectFit="cover"
              />
              {userRole === 'corporate' && (
                <Flex flexDirection="column" ml={2}>
                  {companyName && (
                    <Text fontWeight="semibold" fontSize="sm">
                      {companyName}
                    </Text>
                  )}
                  <Text fontSize="xs">{`Active since ${dayjs(activeSince).format('YYYY')}`}</Text>
                </Flex>
              )}
              {userRole === 'investor' && (
                <Flex flexDirection="column" ml={2}>
                  {fullName.trim().length && (
                    <Text fontWeight="semibold" fontSize="sm">
                      {fullName}
                    </Text>
                  )}
                  <Text fontSize="xs">{`${
                    walletBalance ? formatPrice(walletBalance) : formatPrice(0)
                  } Available`}</Text>
                </Flex>
              )}
              {userRole === 'regulatory' && (
                <Flex flexDirection="column" ml={2}>
                  {fullName.trim().length && (
                    <Text fontWeight="semibold" fontSize="sm">
                      {fullName}
                    </Text>
                  )}
                  <Text fontSize="xs">Regulatory Body</Text>
                </Flex>
              )}
            </Flex>
            <Flex
              mt={10}
              width="100%"
              flexDirection="column"
              pr={5}
              flex={1}
              overflow="hidden auto"
            >
              {navItems.length &&
                navItems.map((navItem, index) => {
                  const { icon, title, to } = navItem
                  const isActive = pathname.includes(to)

                  return (
                    <Flex
                      position="relative"
                      key={index}
                      borderLeftWidth={8}
                      borderLeftColor={isActive ? 'secondary' : 'transparent'}
                      bg={isActive ? 'transparentSecondary' : 'transparent'}
                      width="100%"
                      borderTopRightRadius={10}
                      borderBottomRightRadius={10}
                      alignItems="center"
                      color={isActive ? 'secondary' : 'text'}
                      my={1}
                      _hover={{
                        cursor: 'pointer',
                        borderLeftColor: 'secondary',
                        bg: 'transparentSecondary',
                        color: 'secondary'
                      }}
                      onClick={() => push(to)}
                    >
                      <Box mx={3}>{icon}</Box>
                      <Text my={3} fontWeight={isActive ? 'bold' : 'normal'}>
                        {title}
                      </Text>
                      {to === '/auth/notifications' && (
                        <NotifierBell>
                          <Text fontSize={12} fontWeight={400} color="white" textAlign="center">
                            {notifications ? notifications.length : 0}
                          </Text>
                        </NotifierBell>
                      )}
                    </Flex>
                  )
                })}
              <Button
                variant="link"
                my={5}
                color="secondary"
                display={{ base: 'flex', md: 'none' }}
                leftIcon={<AiTwotoneLock size={30} />}
                onClick={() => {
                  logout && logout()
                  push('/')
                }}
                _hover={{
                  color: 'transparentSecondary'
                }}
              >
                Log Out
              </Button>
            </Flex>
            <Flex
              display={{ base: 'none', md: 'flex' }}
              marginTop="auto"
              bgImage={`url(${images.waveIcon})`}
              bgRepeat="no-repeat"
              bgPosition={{ base: '80% 65%', md: 'center' }}
              bgSize="auto"
              minHeight={{ base: '7rem', md: '15rem' }}
              width="100%"
              alignItems="center"
              direction="column"
              justifyContent="flex-end"
              paddingBottom={10}
            >
              <Button
                variant="link"
                color="white"
                leftIcon={<AiTwotoneLock size={30} />}
                onClick={() => {
                  logout && logout()
                  push('/')
                }}
                _hover={{
                  color: 'secondary'
                }}
              >
                Log Out
              </Button>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      <Flex flexDirection="column" flex={1} width={{ base: '100%', md: '75%' }}>
        <Flex height={{ base: maxHeight }} boxSizing="border-box" overflow="hidden">
          <Flex
            width="100%"
            boxSizing="border-box"
            direction="column"
            overflow="hidden"
            position="relative"
          >
            <Breadcrumbs />
            {children}
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default SideBar

SideBar.defaultProps = {
  color: 'white',
  bg: 'gray.900',
  hoverColor: 'gray.800',
  borderColor: 'gray.700',
  accentColor: 'cyan.500'
}
