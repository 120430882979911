import {
  Flex,
  FormControl,
  FormLabel,
  InputGroup,
  InputLeftAddon,
  NumberInput,
  NumberInputField,
  NumberInputProps,
  Text
} from '@chakra-ui/react'
import { useField } from 'formik'
import omit from 'lodash/omit'
import * as React from 'react'

type ConnectedNumberInputProps = NumberInputProps & {
  label?: string
  name: string
  unit?: string
  placeholder?: string
  showError?: boolean
}

const ConnectedNumberInput: React.FC<ConnectedNumberInputProps> = ({
  label,
  precision,
  unit,
  placeholder,
  showError,
  ...rest
}) => {
  const [field, meta, helpers] = useField(rest.name)

  const isInvalid = meta.touched && meta.error

  return (
    <Flex flexDirection="column" width={rest.width} mr={rest.mr} ml={rest.ml} mt={rest.mt}>
      <FormControl>
        {label && (
          <FormLabel
            htmlFor={field.name}
            fontSize={{ base: 14, md: 'unset' }}
            fontWeight="600"
            color={isInvalid ? 'error.500' : 'text'}
          >
            {label}
          </FormLabel>
        )}
        <InputGroup width="100%">
          {!!unit && <InputLeftAddon>{unit}</InputLeftAddon>}
          <NumberInput
            {...omit(rest, ['bg'])}
            precision={precision}
            step={0.01}
            width="100%"
            value={field.value}
            onChange={(val) => helpers.setValue(val)}
          >
            <NumberInputField
              {...field}
              id={field.name}
              focusBorderColor="accent.500"
              placeholder={placeholder || ''}
              variant="filled"
              bg={isInvalid ? 'error.100' : rest.bg}
              color={isInvalid ? 'error.500' : 'text'}
              borderRadius="50px"
              fontColor="black"
            />
          </NumberInput>
        </InputGroup>
        {showError && isInvalid ? (
          <Text color="red.500" textAlign="left">
            {meta.error}
          </Text>
        ) : null}
      </FormControl>
    </Flex>
  )
}

export default ConnectedNumberInput

ConnectedNumberInput.defaultProps = {
  mb: 2,
  fontWeight: 'lighter',
  precision: 0,
  width: '100%',
  bg: 'background'
}
