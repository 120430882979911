import { Flex, InputProps, Text } from '@chakra-ui/react'
import { useField } from 'formik'
import moment from 'moment'
import * as React from 'react'
import { isInclusivelyBeforeDay, SingleDatePicker } from 'react-dates'
import { DATE_FORMAT } from '../../../constants'
import { Label, LabelProps } from '../styles'
import './datepicker.css'
import { DatePickerSelect, DatePickerWrapper } from './styles'

type ConnectedDatePickerProps = LabelProps &
  InputProps & {
    label?: string
    name: string
    background?: string
    yearsRange?: number
    showError?: boolean
    placeholder?: string
    isBeforeDay?: boolean
  }

const ConnectedDatePicker: React.FC<ConnectedDatePickerProps> = ({
  label,
  yearsRange,
  isDisabled,
  showError,
  placeholder,
  isBeforeDay,
  ...rest
}) => {
  const [field, meta, helpers] = useField(rest.name)
  const [date, setDate] = React.useState(field && field.value ? moment(field.value) : null)
  const [focused, setFocus] = React.useState<boolean>(false)

  const renderYears = () => {
    const start = yearsRange ? 0 : -100
    const stop = yearsRange || 5
    const arr = []
    for (let i = start; i <= stop; i++) {
      arr.push(
        <option key={moment().year() + i} value={moment().year() + i}>
          {moment().year() + i}
        </option>
      )
    }
    return arr
  }

  const isInvalid = meta.touched && meta.error

  return (
    <Flex
      flexDirection="column"
      width={rest.width}
      mr={rest.mr}
      ml={rest.ml}
      mt={rest.mt}
      mb={rest.mb}
    >
      {label ? (
        <Label htmlFor={field.name} fontWeight="600" color="text">
          {label}
        </Label>
      ) : null}
      <DatePickerWrapper backgroundColor={isInvalid ? 'error.100' : rest.background}>
        <SingleDatePicker
          hideKeyboardShortcutsPanel={true}
          displayFormat={DATE_FORMAT}
          placeholder={placeholder}
          numberOfMonths={1}
          onFocusChange={({ focused }) => setFocus(focused)}
          onDateChange={(date) => {
            setDate(date)
            helpers.setValue(date)
          }}
          date={date}
          focused={focused}
          id={field.name}
          disabled={isDisabled}
          renderMonthElement={({ month, onMonthSelect, onYearSelect }) => {
            return (
              <Flex flexDirection="row" justifyContent="center">
                <DatePickerSelect
                  mr={2}
                  name={field.name}
                  value={month.month()}
                  onChange={(e) => {
                    onMonthSelect(month, e.target.value)
                  }}
                >
                  {moment.months().map((lbl, value) => (
                    <option key={value} value={value}>
                      {lbl}
                    </option>
                  ))}
                </DatePickerSelect>
                <DatePickerSelect
                  name={field.name}
                  value={month.year()}
                  onChange={(e) => {
                    onYearSelect(month, e.target.value)
                  }}
                >
                  {renderYears()}
                </DatePickerSelect>
              </Flex>
            )
          }}
          {...(isBeforeDay && { isOutsideRange: (day) => !isInclusivelyBeforeDay(day, moment()) })}
        />
      </DatePickerWrapper>
      {isInvalid && showError ? (
        <Text color="red.500" textAlign="right">
          {meta.error}
        </Text>
      ) : null}
    </Flex>
  )
}

export default ConnectedDatePicker

ConnectedDatePicker.defaultProps = {
  mb: 2,
  fontWeight: 'lighter',
  width: '100%',
  placeholder: 'Date of Registration'
}
