import {
  Flex,
  FormControl,
  FormLabel,
  Icon,
  Input,
  InputProps,
  Text,
  Tooltip
} from '@chakra-ui/react'
import { useField } from 'formik'
import omit from 'lodash/omit'
import * as React from 'react'
import { HiInformationCircle } from 'react-icons/hi'
import { LabelProps } from '../styles'

export type ConnectedFormGroupProps = LabelProps &
  InputProps & {
    label?: string
    name: string
    showError?: boolean
    placeholder?: string
    inputStyles?: InputProps
    toolTipText?: string
    disabled?: boolean
  }

const ConnectedFormGroup: React.FC<ConnectedFormGroupProps> = ({
  label,
  name,
  showError,
  placeholder,
  inputStyles,
  toolTipText,
  disabled = false,
  ...rest
}) => {
  const [field, meta] = useField(name)

  const isInvalid = meta.touched && meta.error

  return (
    <Flex flexDirection="column" mb={rest.mb} mt={rest.mt} width={rest.width}>
      {label ? (
        <Flex flexDirection="row" alignItems="center">
          <FormLabel htmlFor={field.name} fontWeight="600" color={isInvalid ? 'error.500' : 'text'}>
            {label}
          </FormLabel>
          {toolTipText && (
            <Tooltip
              hasArrow
              color="background"
              label={toolTipText}
              placement="right-end"
              closeDelay={500}
            >
              <span>
                <Icon as={HiInformationCircle} color="secondary" />
              </span>
            </Tooltip>
          )}
        </Flex>
      ) : null}
      <Flex flexDirection="column" {...omit(rest, ['bg', 'width'])}>
        <FormControl>
          <Input
            focusBorderColor="accent.500"
            {...field}
            id={field.name}
            {...inputStyles}
            placeholder={placeholder || ''}
            variant="filled"
            bg={isInvalid ? 'error.100' : rest.bg}
            color={isInvalid ? 'error.500' : 'text'}
            borderRadius="50px"
            fontColor="black"
            disabled={disabled}
          />
          {showError && isInvalid ? (
            <Text color="red.500" textAlign="left">
              {meta.error}
            </Text>
          ) : null}
        </FormControl>
      </Flex>
    </Flex>
  )
}

export default ConnectedFormGroup

ConnectedFormGroup.defaultProps = {
  mb: 2,
  type: 'text',
  showError: true,
  bg: 'background'
}
