import SideBar from 'components/SideBar'
import { NAV_ITEMS as INVESTOR_NAV_ITEMS } from 'containers/Investor/navigation'
import { INVESTOR_ROUTES } from 'containers/Investor/navigation/routes'
import { MANAGER_NAV_ITEMS } from 'containers/Manager/navigation'
import { MANAGER_ROUTES } from 'containers/Manager/navigation/routes'
import PageNotFound from 'containers/PageNotFound'
import { REGULATORY_ROUTES } from 'containers/RegulatoryBody/navigation/routes'
import { RoleType, useAuthContext } from 'context/AuthProvider'
import { AUTH_ROUTES, PrivateRouteObject, PRIVATE_ROUTES, PUBLIC_ROUTES } from 'navigation/routes'
import React, { Suspense } from 'react'
import { BsFillBarChartFill, BsFillQuestionCircleFill } from 'react-icons/bs'
import { FaBell, FaFolder, FaUser } from 'react-icons/fa'
import { Redirect, Route, RouteProps, Switch } from 'react-router'
import { BrowserRouter as Router } from 'react-router-dom'
import { FillLoader } from '../components'

interface RouteType extends RouteProps {
  component: any
  role?: RoleType
}

export type NavItem = {
  to: string
  title: string
  icon: React.ReactNode
  subMenu?: Array<{ to: string; title: string }>
}

export const NAV_ITEMS: NavItem[] = [
  {
    to: '/auth/my-dashboard',
    icon: <BsFillBarChartFill size={20} />,
    title: 'My Dashboard'
  },
  {
    title: 'My Notes',
    to: '/auth/notes',
    icon: <FaFolder size={20} />
  },
  {
    to: '/auth/profile',
    title: 'My Profile',
    icon: <FaUser size={20} />
  },
  {
    to: '/auth/notifications',
    title: 'Notifications',
    icon: <FaBell size={20} />
  },
  {
    to: '/auth/help-and-support',
    title: 'Help & Support',
    icon: <BsFillQuestionCircleFill size={20} />
  }
]

const PrivateRoute = ({ component: Component, ...rest }: RouteType) => {
  const { isAuthenticating, isAuthenticated, userRole } = useAuthContext()
  const route = rest as PrivateRouteObject
  if (isAuthenticating) {
    return <FillLoader />
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated && userRole ? (
          <Suspense fallback={<FillLoader color="black" />}>
            {route.hideSideBar ? (
              <Component {...rest} />
            ) : (
              <SideBar
                bg="gray.900"
                color="white"
                navItems={getNavItems(userRole).navItems}
                hoverColor="gray.700"
                accentColor="cyan.500"
                routes={getNavItems(userRole).routes}
              >
                <Component {...rest} />
              </SideBar>
            )}
          </Suspense>
        ) : (
          <Redirect to={{ pathname: '/', state: { from: props.location } }} />
        )
      }
    />
  )
}

const RegulatoryBodyRoute = ({ component: Component, role, ...rest }: RouteType) => {
  const { hasAccessTo, userRole } = useAuthContext()
  const route = rest as PrivateRouteObject

  return (
    <Route
      {...rest}
      render={() =>
        userRole && hasAccessTo && hasAccessTo(role as RoleType) ? (
          <Suspense fallback={<FillLoader color="black" />}>
            {route.hideSideBar ? (
              <Component {...rest} />
            ) : (
              <SideBar
                bg="gray.900"
                color="white"
                navItems={getNavItems(userRole).navItems}
                hoverColor="gray.700"
                accentColor="cyan.500"
                routes={getNavItems(userRole).routes}
              >
                <Component {...rest} />
              </SideBar>
            )}
          </Suspense>
        ) : (
          <Route render={PageNotFound} />
        )
      }
    />
  )
}

const PublicRoute = ({ component: Component, ...rest }: RouteType) => (
  <Route {...rest}>
    <Component />
  </Route>
)

export const getNavItems = (
  userRole: RoleType
): { navItems: NavItem[]; routes: PrivateRouteObject[] } => {
  switch (userRole) {
    case 'corporate':
      return { navItems: NAV_ITEMS, routes: PRIVATE_ROUTES }
    case 'investor':
      return { navItems: INVESTOR_NAV_ITEMS, routes: INVESTOR_ROUTES }
    case 'regulatory':
      return { navItems: NAV_ITEMS, routes: REGULATORY_ROUTES }
    case 'manager':
      return { navItems: MANAGER_NAV_ITEMS, routes: MANAGER_ROUTES }
    case 'authenticated':
      return { navItems: [], routes: AUTH_ROUTES }
    default:
      return { navItems: NAV_ITEMS, routes: PRIVATE_ROUTES }
  }
}

const Navigation = (): JSX.Element => {
  const { userRole } = useAuthContext()

  return (
    <Router>
      <Suspense fallback={<FillLoader />}>
        <Switch>
          {PUBLIC_ROUTES.map((route) => {
            return <PublicRoute key={route.path} {...route} />
          })}
          {userRole && (
            <Route
              path="/auth"
              render={() => (
                <>
                  {userRole === 'corporate' &&
                    PRIVATE_ROUTES.map((route) => {
                      return <PrivateRoute key={route.path} {...route} />
                    })}
                  {userRole === 'regulatory' &&
                    REGULATORY_ROUTES.map((route) => {
                      return <RegulatoryBodyRoute key={route.path} role={'regulatory'} {...route} />
                    })}
                  {userRole === 'manager' &&
                    MANAGER_ROUTES.map((route) => {
                      return <RegulatoryBodyRoute key={route.path} role={'manager'} {...route} />
                    })}
                  {userRole === 'investor' &&
                    INVESTOR_ROUTES.map((route) => {
                      return <PrivateRoute key={route.path} {...route} />
                    })}
                  {userRole === 'authenticated' &&
                    AUTH_ROUTES.map((route) => {
                      return <PrivateRoute key={route.path} {...route} />
                    })}
                </>
              )}
            />
          )}

          <Route render={PageNotFound} />
        </Switch>
      </Suspense>
    </Router>
  )
}

export default Navigation
